import { useContext } from "react";
import DataContext from "../../context/DataContext";
import styles from "../components.module.sass";
import { TitleComponent } from "../ui/TitleComponent";
import { ImageWbd } from "./ImageWbd";
import { Button, Checkbox, FormControlLabel, Slider } from "@mui/material";
import { ProductProps } from "../../interfaces/interfaces";
import { RichTextEditorComponent } from "../rich-text/RichTextEditorComponent";

const marks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 5,
    label: "5",
  },
];

export const WBDDesign = () => {
  const {
    data: { wbdDesign },
    onUpdateTally,
    handleFetchData,
    handlePutData,
  } = useContext(DataContext);

  const handleUpdateSize = (
    index: number,
    item: ProductProps,
    value: number | number[]
  ) => {
    onUpdateTally(index, { ...item, size: value }, true);
  };

  return (
    <>
      <TitleComponent title="Wellbore Design" />
      {wbdDesign.length > 0 && (
        <div className={styles.wbdContainer}>
          <table className={styles.tableWbd}>
            <thead>
              <tr>
                <th>Tool</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              {wbdDesign.map((item, index) => (
                <tr key={index}>
                  <td style={{ width: "30%" }}>
                    <div className={styles.toolDescription}>
                      {item.tool.name || item.tool.description}
                    </div>
                    <ImageWbd item={item} />
                    <Slider
                      orientation="horizontal"
                      size="small"
                      value={item.tool.size}
                      step={1}
                      marks={marks}
                      min={0}
                      max={5}
                      valueLabelDisplay="auto"
                      onChange={(_, value) =>
                        value && handleUpdateSize(index, item.tool, value)
                      }
                    />
                  </td>
                  <td style={{ width: "70%" }}>
                    <FormControlLabel
                      value="includeRichText"
                      control={
                        <Checkbox
                          checked={item.tool.includeRichText}
                          onChange={async (e) => {
                            let checked = !item.tool.includeRichText;
                            if (checked) {
                              const response = await handleFetchData({
                                endpoint: `product/${item.tool.id}`,
                              });
                              response &&
                                onUpdateTally(
                                  index,
                                  {
                                    ...item.tool,
                                    toolRichText: response,
                                  },
                                  true
                                );
                            } else {
                              onUpdateTally(
                                index,
                                {
                                  ...item.tool,
                                  toolRichText: "",
                                },
                                true
                              );
                            }
                            onUpdateTally(
                              index,
                              {
                                ...item.tool,
                                includeRichText: !item.tool.includeRichText,
                              },
                              true
                            );
                          }}
                        />
                      }
                      label="Include Description?"
                      style={{ marginLeft: "10px" }}
                    />
                    {item.tool.includeRichText && (
                      <RichTextEditorComponent
                        values={item.tool.toolRichText}
                        onChange={(value: any) => {
                          onUpdateTally(
                            index,
                            {
                              ...item.tool,
                              toolRichText: value,
                            },
                            true
                          );
                        }}
                        additionalOptions={
                          <>
                            <div>
                              <Button
                                variant="contained"
                                onClick={() =>
                                  handlePutData({
                                    endpoint: `product/${item.tool.id}`,
                                    newData: {
                                      toolRichText: item.tool.toolRichText,
                                    },
                                  })
                                }
                                style={{ marginTop: "10px" }}
                              >
                                Save Description
                              </Button>
                            </div>
                          </>
                        }
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};
