import React, { ReactNode } from "react";
import RichTextEditor, {
  Image,
  BaseKit,
  Bold,
  BulletList,
  Clear,
  Color,
  FontFamily,
  FontSize,
  Heading,
  Indent,
  Italic,
  Link,
  ColumnActionButton,
  OrderedList,
  Table,
} from "reactjs-tiptap-editor";
import "reactjs-tiptap-editor/style.css";

const extensions = [
  BaseKit.configure({
    // Show placeholder
    placeholder: {
      showOnlyCurrent: true,
    },
    // Character count
    characterCount: {
      limit: 50000, // Removed numeric separator
    },
  }),
  Image.configure({
    upload: (files: File) => {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(URL.createObjectURL(files));
        }, 500);
      });
    },
  }),
  Bold,
  Italic,
  BulletList,
  Clear,
  Color,
  FontSize,
  Heading,
  Indent,
  FontFamily.configure({
    fontFamilyList: ["Arial", "Tahoma", "Verdana"],
  }),
  Link,
  ColumnActionButton,
  OrderedList,
  Table,
];

const DEFAULT = "";

export const RichTextEditorComponent = ({
  values,
  onChange,
  additionalOptions,
}: {
  values: any;
  onChange: (newValue: any) => void;
  additionalOptions?: ReactNode;
}) => {
  return (
    <div>
      <RichTextEditor
        output="html"
        content={values || DEFAULT}
        onChangeContent={onChange}
        extensions={extensions}
        hideBubble={true}
        dense={true}
        label="Description"
        maxHeight={50}
      />
      {additionalOptions && <>{additionalOptions}</>}
    </div>
  );
};
