import React from "react";
import { Page, Text, StyleSheet, View, Image } from "@react-pdf/renderer";
import { DataProps } from "../../../interfaces/interfaces";
import { Header } from "../components/Header";
import Footer from "../components/Footer";
import parse from "html-react-parser";

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  page: {
    padding: "50px",
    fontSize: 14,
    paddingBottom: "100px",
  },
  title: {
    fontSize: 15,
    marginBottom: 0,
    textAlign: "center",
    fontFamily: "Times-Roman",
    fontWeight: 600,
  },
  titleProd: {
    fontSize: 15,
    marginTop: 50,
    marginBottom: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    fontWeight: 600,
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
    fontWeight: "bold",
  },
  image: {
    width: 600,
    marginTop: 2,
    textAlign: "center",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "rgb(90,100,119)",
    color: "#fff",
    padding: 3,
  },
  table: {
    display: "flex",
    width: "auto",
    borderStyle: "solid",
    marginTop: 30,
    borderWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    fontSize: 7,
    justifyContent: "space-between",
    alignItems: "center",
    borderLeft: "1px solid black",
    borderTop: "1px solid black",
    borderBottom: "1px solid black",
  },
  tableRowMain: {
    margin: "auto",
    flexDirection: "row",
    fontSize: 7,
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#2F75B5",
  },
  tableCol: {
    width: "40%",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderRight: "1px solid black",
    borderTopWidth: 0,
  },
  tableColMain: {
    width: "80%",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: "auto",
    fontSize: 12,
    paddingTop: 2,
    paddingBottom: 2,
    fontFamily: "Times-Roman",
  },
  prodChart: {
    display: "flex",
    width: "auto",
    justifyContent: "center",
    textAlign: "center",
    alignContent: "center",
    alignItems: "center",
    marginTop: "1%",
  },
  paragraph: {
    marginBottom: 10,
    fontSize: 12,
  },
  heading1: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 10,
  },
  heading3: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 8,
  },
  bold: {
    fontWeight: "bold",
  },
});

export const ToolDescriptionPage = ({
  data: {
    basicInfo: { client, well },
    wbdDesign,
  },
}: {
  data: DataProps;
}) => {
  const renderHTML = (htmlString: string) => {
    const mapHtmlToPdf = (node: any): React.ReactNode => {
      if (node.type === "tag") {
        switch (node.name) {
          case "p":
            return (
              <Text style={styles.paragraph} key={node.children?.[0]?.data}>
                {node.children.map(mapHtmlToPdf)}
              </Text>
            );
          case "h1":
            return (
              <Text style={styles.heading1} key={node.children?.[0]?.data}>
                {node.children.map(mapHtmlToPdf)}
              </Text>
            );
          case "h3":
            return (
              <Text style={styles.heading3} key={node.children?.[0]?.data}>
                {node.children.map(mapHtmlToPdf)}
              </Text>
            );
          case "strong":
            return (
              <Text style={styles.bold} key={node.children?.[0]?.data}>
                {node.children.map(mapHtmlToPdf)}
              </Text>
            );
          default:
            return node.data; // Handle text or unsupported tags
        }
      }
      if (node.type === "text") {
        return node.data;
      }
      return null;
    };

    const parsedNodes = parse(htmlString, {
      replace: (node) => mapHtmlToPdf(node),
    });

    return parsedNodes;
  };

  return (
    <Page style={styles.page}>
      <Header title={client.name} subtitle={well.name} />
      <Text style={styles.title}>Tool Description</Text>

      {wbdDesign.map((item) => (
        <>
          {item.tool.includeRichText && (
            <View>{renderHTML(item.tool.toolRichText)}</View>
          )}
        </>
      ))}
      <Footer />
    </Page>
  );
};
